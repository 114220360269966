import { defineNuxtPlugin } from "#app"
import { useUserStore } from "@/store/user"

export default defineNuxtPlugin(() => {
  const { $bus } = useNuxtApp()
  const toast = useToast()
  const userStore = useUserStore()
  const contactsStore = useContactsStore()
  const route = useRoute()

  $bus.$on("new-direct-message", async ({ payload }) => {
    const isOwnerOfMessage = userStore.username === payload.sender.username
    if (isOwnerOfMessage) return

    if (route.path != '/messages') {
      console.log("--> socketNotifications:new-direct-message")
      await contactsStore.addNewMessage(payload)
      await contactsStore.updateUnreadMessageCount()

      toast.add({
        title: `New Message from @${payload.sender.username}`,
        avatar: { src: payload.sender.signedProfilePhotoThumbUrl },
        description: payload.text,
        color: 'green',
        duration: 9000,
        isClosable: true,
      })
    }
  })
})
