import { defineStore } from "pinia"
import { jwtDecode } from "jwt-decode"
export interface JwtPayload {
  exp: number
  nbf: number
  iat: number
  aud: string
  user_id: string
  name: string
}

const defaultUserState = {
  _admin: false,
  _ageConfirmed: false,
  _affiliate: false,
  _affiliateCode: null,
  _affiliatePayoutPercentage: 0,
  _approvedCreator: false,
  _arrested: false,
  _avatarUrl: null,
  _authenticatorUrl: null,
  _autoPostToX: false,
  _backgroundUrl: null,
  _bio: null,
  _changedUsername: null,
  _displayName: null,
  _email: null,
  _emailConfirmed: null,
  _hasPrimaryPaymentAccount: false,
  _hasCompletedBusinessAccount: false,
  _id: null,
  _instagramProfile: null,
  _otpEnabled: null,
  _phoneNumber: null,
  _photoUrl: null,
  _preferences: null,
  _receiveEmail: null,
  _receivePostEmail: null,
  _receiveMarketingEmail: null,
  _receiveNewsletterEmail: null,
  _referralCap: 5000000,
  _refreshToken: null,
  _token: null,
  _tokenBalance: 0,
  _twitterProfile: null,
  _username: null,
  _wishlist: null,
  _x_connected: false,
  _discord_connected: false,
  jwt: <JwtPayload>{},
}

export const useUserStore = defineStore("user-20250401", {
  state: () => ({ ...defaultUserState }),

  persist: {
    storage: persistedState.cookiesWithOptions({
      expires: new Date(Date.now() + 60 * 24 * 60 * 30 * 1000), // 30 days from now
      maxAge: 60 * 60 * 24 * 30, // 30 days in seconds
      sameSite: "strict",
    }),
  },

  getters: {
    admin: (state: UserState) => state._admin,
    affiliate: (state: UserState) => state._affiliate,
    affiliateCode: (state: UserState) => state._affiliateCode,
    affiliatePayoutPercentage: (state: UserState) =>
      state._affiliatePayoutPercentage,
    ageConfirmed: (state: UserState) => state._ageConfirmed,
    approvedCreator: (state: UserState) => state._approvedCreator,
    authenticatorUrl: (state: UserState) => state._authenticatorUrl,
    arrested: (state: UserState) => state._arrested,
    avatarUrl: (state: UserState) => state._avatarUrl,
    autoPostToX: (state: UserState) => state._autoPostToX,
    backgroundUrl: (state: UserState) => state._backgroundUrl,
    changedUsername: (state: UserState) =>
      state._changedUsername ? true : false,
    compliance: (state: UserState) => state._email == "compliance@favoritely.com",
    customerService: (state: UserState) =>
      state.jwt?.aud.match(/^customerservice/i),
    displayName: (state: UserState) => state._displayName,
    email: (state: UserState) => state._email,
    emailConfirmed: (state: UserState) => state._emailConfirmed,
    hasCompletedBusinessAccount: (state: UserState) =>
      state._hasCompletedBusinessAccount,
    hasPrimaryPaymentAccount: (state: UserState) =>
      state._hasPrimaryPaymentAccount,
    id: (state: UserState) => state._id,
    instagramProfile: (state: UserState) => state._instagramProfile,
    loggedIn: (state: UserState) => !!state._token,
    otpEnabled: (state: UserState) => state._otpEnabled,
    phoneNumber: (state: UserState) => state._phoneNumber,
    preferences: (state: UserState) => state._preferences,
    bio: (state: UserState) => state._bio,
    profileUrl: (state: UserState) =>
      !!state._token ? "/" + state._username.toLowerCase() : null,
    refreshToken: (state: UserState) => state._refreshToken,
    receiveEmail: (state: UserState) => state._receiveEmail,
    receivePostEmail: (state: UserState) => state._receivePostEmail,
    receiveMarketingEmail: (state: UserState) => state._receiveMarketingEmail,
    receiveNewsletterEmail: (state: UserState) => state._receiveNewsletterEmail,
    referralCap: (state: UserState) => state._referralCap,
    role: (state: UserState) => state.jwt?.aud,
    token: (state: UserState) => state._token,
    tokenBalance: (state: UserState) => state._tokenBalance,
    userId: (state: UserState) => state.jwt?.user_id,
    username: (state: UserState) => state._username,
    superuser: (state: UserState) => state.jwt?.aud.match(/^superuser/i),
    twitterProfile: (state: UserState) => state._twitterProfile,
    wishlist: (state: UserState) => state._wishlist,
    xConnected: (state: UserState) => state._x_connected,
    discordConnected: (state: UserState) => state._discord_connected,
  },

  actions: {
    reset() {
      Object.assign(this, defaultUserState)
    },

    async getData() {
      const config = useRuntimeConfig()

      if (this.loggedIn) {
        try {
          const data = await $api(`${config.public.API_URL}/api/me`)

          if (data.username) {
            this._affiliate = data.affiliate
            this._affiliatePayoutPercentage = data.affiliatePayoutPercentage
            this._affiliateCode = data.affiliateCode
            this._approvedCreator = data.approvedCreator
            this._authenticatorUrl = data.authenticatorUrl
            this._arrested = data.arrested
            this._avatarUrl = data.avatarUrl
            this._autoPostToX = data.autoPostToX
            this._backgroundUrl = data.backgroundUrl
            this._changedUsername = data.changedUsername
            this._email = data.email
            this._emailConfirmed = data.emailConfirmed
            this._displayName = data.displayName
            this._hasPrimaryPaymentAccount = data.hasPrimaryPaymentAccount
            this._hasCompletedBusinessAccount = data.hasCompletedBusinessAccount
            this._otpEnabled = data.otpEnabled
            this._receiveEmail = data.receiveEmail
            this._receivePostEmail = data.receivePostEmail
            this._receiveMarketingEmail = data.receiveMarketingEmail
            this._receiveNewsletterEmail = data.receiveNewsletterEmail
            this._referralCap = data.referralCap
            this._tokenBalance = data.tokenBalance
            this._username = data.username
            this._phoneNumber = data.phoneNumber
            this._bio = data.bio
            this._twitterProfile = data.twitterProfile
            this._instagramProfile = data.instagramProfile
            this._wishlist = data.wishlist
            this._preferences = data.preferences
            this._x_connected = data.xConnected
            this._discord_connected = data.discordConnected
          }

          if (data.arrested) {
            navigateTo("/account/holding")
          }


        } catch (error) {
          console.error("Error fetching user data:", error)
          await this.logout()
        }
      }
    },

    // Required timeout to resolve race condition when refreshing store
    // after User data value change.
    async refresh() {
      await setTimeout(this.getData, 100)
    },

    // Attempts to log in the user using their email and password. On success, returns the user object.
    async login(email: string, password: string) {
      const config = useRuntimeConfig()
      const data = await $fetch(`${config.public.API_URL}/api/sign_ins`, {
        method: "POST",
        body: {
          user: {
            email: email,
            password: password,
          },
        },
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!data.success) {
        throw new Error(data.error)
      }

      if (data.otpEnabled) {
        return { success: true, waitingForOtp: true }
      } else {
        this._token = data.token!
        this._refreshToken = data.refreshToken!
        this.jwt = jwtDecode(this._token)
        await this.getData()

        // Preload conversations on login
        const contactsStore = useContactsStore()
        await contactsStore.fetchConversations()

        return { success: true, waitingForOtp: false }
      }
    },

    async setLoginDetails(data) {
      if (data.success) {
        this._admin = data.admin
        this._token = data.token!
        this._refreshToken = data.refreshToken!
        this.jwt = jwtDecode(this._token)
        await this.getData()
        return { success: true }
      } else {
        return { success: false }
      }
    },

    async authenticate(email: string, code: string) {
      const config = useRuntimeConfig()
      const data = await $fetch(`${config.public.API_URL}/api/authenticate`, {
        method: "POST",
        body: {
          email: email,
          code: code,
        },
        headers: {
          "Content-Type": "application/json",
        },
        async onResponseError({ request, response, options }) {
          throw new Error("Invalid code.")
        },
      })

      return this.setLoginDetails(data)
    },

    async loginWithMagicToken(token: string) {
      const config = useRuntimeConfig()
      const data = await $fetch(
        `${config.public.API_URL}/api/authenticate/magic_token`,
        {
          method: "POST",
          body: {
            token: token,
          },
          headers: {
            "Content-Type": "application/json",
          },
          async onResponseError({ request, response, options }) {
            throw new Error("Invalid code.")
          },
        },
      )

      return this.setLoginDetails(data)
    },

    async logout() {
      useLogoutTracking()
      this.reset()

      // Clear contacts store before logout completes
      const contactsStore = useContactsStore()
      contactsStore.reset()

      const router = useRouter()
      const route = useRoute()
      router.push(`/login?redirectBackTo=${route.fullPath}`)
    },

    async clearLoginData() {
      useLogoutTracking()
      this.reset()

      const contactsStore = useContactsStore()
      contactsStore.reset()
    },

    refreshSession(token, refreshToken) {
      this._token = token
      this._refreshToken = refreshToken
      this.getData()
    },

    async updateTokenBalance(new_balance) {
      this._tokenBalance = new_balance
    },

    async confirmAge() {
      this._ageConfirmed = true
    },

    async setAutoPostToX(value) {
      this._autoPostToX = value
    }
  },
})
