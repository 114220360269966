import { defineStore } from "pinia";

const PER_PAGE = 50;

interface Pagination {
  nextPage: string | null
  previousPage: string | null
  totalPages: number
  totalItems: number
}

interface Contact {
  displayName: string
  isCreator: boolean
  avatarUrl: string | null
  username: string
  lastActiveAt: string | null
  following: boolean
}

interface Conversation {
  id: string
  open: boolean
  unread: boolean
  lastMessageText: string | null
  lastSentById: string | null
  lastSentAt: string | null
  lastSentTipAmount: number
  hasTips: boolean
  guest: Contact
}

interface Media {
  id: string
  blurhash: string
  durationInSeconds: number
  hasAccess: boolean
  height: number
  locked: boolean
  mediaId: string
  nsfw: boolean
  rejected: boolean
  signedThumbnailUrl: string | null
  signedUrl: string | null
  status: string
  tags: string[]
  type: string
  width: number
}

interface Message {
  flagged: boolean
  hash: string
  id: string
  locked: boolean
  media: Media[]
  purchased: boolean
  reaction: string | null
  read: boolean
  sender: Contact
  sentAt: string
  status: string
  text: string
  tipAmount: number
  tipCount: number
}

interface Messages {
  conversation: Conversation
  allowedToRespond: boolean
  messages: Message[]
  pagination: Pagination
}

interface MessageCount {
  unread: number
}

interface MessageMedia {
  id: string
  locked: boolean
}

interface MessagePayload {
  guestUsername: string
  text: string
  tipAmount: number
  unlockAmount: number
  media: MessageMedia[]
}

interface Loading {
  search: boolean
  conversations: boolean
  messages: boolean
}

interface Reaction {
  directMessageHash: string
  reaction: string
}

interface Search {
  query: string,
  results: Conversation[]
}

interface ConversationState {
  active: Conversation | null
  loading: Loading
  new: Conversation[]
  contacts: Conversation[]
  search: Search
  selected: String[] // convo ids
  messages: Message[] // messages for active conversation
  page: number
  perPage: number
  order: string
  sort: string
  totalPages: number
  totalItems: number
  unreadCount: number
}

export const useContactsStore = defineStore("conversations", {
  state: (): ConversationState => ({
    active: null,
    loading: {
      search: false,
      conversations: false,
      messages: false
    },
    new: [],
    contacts: [],
    selected: [],
    messages: [],
    search: {
      query: '',
      results: []
    },
    page: 1,
    perPage: PER_PAGE,
    order: "default",
    sort: "desc",
    totalPages: 0,
    totalItems: 0,
    unreadCount: 0
  }),
  actions: {
    /******************************************************
     *   	Search Actions
     *****************************************************/ 
    async searchConversations(query: string) {
      // Reset search results
      this.search.results = []

      if (!query) {
        this.search.query = ''
        return
      }

      this.loading.search = true
      this.search.query = query

      const config = useRuntimeConfig()
      await $api(`${config.public.API_URL}/api/conversations/search`, {
        query: { query },
        onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
          // Update search results
          this.search.results = context.response._data.items
        }
      })
      this.loading.search = false
    },

    async clearSearch() {
      this.search.query = ''
      this.search.results = []
      this.active = null
    },

    /******************************************************
     *   	Conversations/Contacts Actions
     *****************************************************/ 
    async fetchConversations(
      page : number = this.page,
      order : string = this.order,
      sort : string = this.sort
    ) {
      this.loading.conversations = true
      
      const config = useRuntimeConfig()
      const userStore = useUserStore()
      const endpoint = `${config.public.API_URL}/api/conversations`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          query: { page, order, sort },
          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {
              // Update State Page Data 
              this.page = page
              this.hasMore = !!context.response._data.pagination.nextPage
              this.totalPages = context.response._data.pagination.totalPages
              this.totalItems = context.response._data.pagination.totalItems
    
              // For dup checking
              const unreadIds : string[] = this.new.map((x: Conversation) => x.id)
              const contactIds : string[] = this.contacts.map((x: Conversation) => x.id)
    
              // Load conversations into the store's state
              context.response._data.items.forEach((c: Conversation) => {
                if (c.unread) {
                  // New Unread Conversations (dup check)
                  if (!unreadIds.includes(c.id)) {
                    this.new.push(c)
                  }
                } else {
                  // Past Conversations (dup check)
                  if (!contactIds.includes(c.id)) {
                    this.contacts.push(c)
                  }
                }
              })
    
              this.sortConversations(this.new)
              this.sortConversations(this.contacts)
            } else {
              console.error("Error fetching conversations:", context.response?._data)
              throw new Error("Failed to fetch conversations.")
            }
          }
        })
      }
      // Update loading state
      this.loading.conversations = false
    },

    async nextPage() {
      if (this.page >= this.totalPages) {
        this.hasMore = false
      } else {
        this.fetchConversations(this.page + 1, this.order, this.sort)
      }
    },

    async markConversationsAsRead() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/mark_all_as_read`

      const selectMode = this.selected.length > 0
      const conversationIds = selectMode
        ? this.selected
        : this.getAllConversationIds()

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            conversation_ids: conversationIds
          },
          
          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {
              
              // Handle Selected
              if (selectMode) {
                conversationIds.forEach((id: string) => {
                  const conversation = this.new.find((c: Conversation) => c.id === id)
                  if (conversation) {
                    conversation.unread = false
                    this.moveConversationFromNewToContacts(conversation)
                  }
                })
                this.sortConversations(this.contacts)
                this.sortConversations(this.new)
              } 
  
              // Handle All
              else {
                this.new.forEach((c: Conversation) => {
                  c.unread = false
                  this.contacts.unshift(c)
                })
                this.sortConversations(this.contacts)
                this.new = []
              }
              this.active = null
            } else {
              console.error("Error marking conversation as read:", context.response?._data)
              throw new Error("Failed to mark conversations as read.")
            }
          }
        })
      }
    },

    async markConversationsAsUnread() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/mark_all_as_unread`
      
      const selectMode = this.selected.length > 0
      const conversationIds = selectMode ? this.selected : this.getAllConversationIds()

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            conversation_ids: conversationIds
          },

          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {
              
              // Handle Selected
              if (selectMode) {
                conversationIds.forEach((id: string) => {
                  const conversation = this.contacts.find((c: Conversation) => c.id === id)
                  if (conversation) {
                    conversation.unread = true
                    this.moveConversationFromContactsToNew(conversation)
                  }
                })
                this.sortConversations(this.contacts)
                this.sortConversations(this.new)
              } 

              // Handle All
              else {
                // Update the state to reflect that all conversations are unread
                this.contacts.forEach((c: Conversation) => {
                  c.unread = true
                  this.new.unshift(c)
                })
                
                this.sortConversations(this.new)
                this.contacts = []
                this.active = null
              }
            } else {
              console.error("Error marking conversation as unread:", context.response?._data)
              throw new Error("Failed to mark conversations as unread.")
            }
          }
        })
      }
    },

    async deleteConversations() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/delete_all`

      const selectMode = this.selected.length > 0
      const conversationIds = selectMode 
        ? this.selected
        : this.getAllConversationIds()
      
      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "DELETE",
          body: {
            conversation_ids: conversationIds
          },

          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {

              // Handle Selected
              if (selectMode) {
                // New Messages
                conversationIds.forEach((id: string) => {
                  const index = this.new.findIndex((c: Conversation) => c.id === id)
                  if (index !== -1) {
                    this.new.splice(index, 1)
                  }
                })
                // Past Messages
                conversationIds.forEach((id: string) => {
                  const index = this.contacts.findIndex((c: Conversation) => c.id === id)
                  if (index !== -1) {
                    this.contacts.splice(index, 1)
                  }
                })
              }
              
              // Update the state to reflect that all conversations are unread
              this.active = null
              this.selected = []
              
              this.sortConversations(this.new)
              this.sortConversations(this.contacts)
            } else {
              console.error("Error deleting conversation:", context.response?._data)
              throw new Error("Failed to delete conversations.")
            }
          }
        })
      }
    },

    async closeConversations() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/close_all`

      const selectMode = this.selected.length > 0
      const conversationIds = selectMode 
        ? this.selected 
        : this.getAllConversationIds()

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            conversation_ids: conversationIds
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {
  
              // Handle Selected
              if (selectMode) {
                conversationIds.forEach((id: string) => {
                  const index = this.contacts.findIndex((c: Conversation) => c.id === id)
                  if (index) this.contacts.splice(index, 1)
                })
                conversationIds.forEach((id: string) => {
                  const index = this.new.findIndex((c: Conversation) => c.id === id)
                  if (index) this.new.splice(index, 1)
                })
                this.sortConversations(this.contacts)
                this.sortConversations(this.new)
              }
              // Handle All
              else {
                this.new = []
                this.contacts = []
              }
              this.active = null
            } else {
              console.error("Error closing conversation:", context.response?._data)
              throw new Error("Failed to close conversations.")
            }
          }
        })
      }
    },

    async markCurrentConversationsAsRead() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/mark_as_read/${this.active.id}`

      $api(endpoint, {
        method: "POST",
        onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
          if (context.response.ok) {
            // Update the state to reflect that the current conversation is read
            this.active.unread = false
            this.messages.forEach((m: Message) => {
              m.read = true
            })
          } else {
            console.error("Error marking current conversation as read:", context.response?._data)
            throw new Error("Failed to mark current conversation as read.")
          }
        }
      })
    },

    async markCurrentMessageAsRead(message: Message) {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/mark_message_as_read`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            message_id: message.id,
            dm_hash: message.hash
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Conversation> } }) => {
            if (context.response.ok) {
              const messageIndex = this.messages.findIndex((m: Message) => m.id === message.id)
              if (messageIndex !== -1) {
                this.messages[messageIndex].read = true
              }
            } else {
              console.error("Error marking current message as read:", context.response?._data)
              throw new Error("Failed to mark current message as read.")
            }
          }
        })
      }
    },
    
    /******************************************************
     *   	Messages Actions
    *****************************************************/ 
    async fetchMessages() {
      this.loading.messages = true
     
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/${this.active.guest.username}`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          onResponse: (context: { response: { _data: ApiResponse<Messages> } }) => {
            if (context.response.ok) {
              this.messages = context.response._data.messages?.reverse() || []
            } else {
              console.error(`Error fetching messages:`, context.response?._data)
              throw new Error("Failed to fetch messages.")
            }
          }
        })
      }
      this.loading.messages = false
    },

    async updateUnreadMessageCount() {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/unread_count`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          onResponse: (context: { response: { _data: ApiResponse<MessageCount> } }) => {
            if (context.response.ok) {
              this.unreadCount = context.response._data.unread
            } else {
              console.error(`Error fetching message counts:`, context.response?._data)
              throw new Error("Failed to fetch message counts.")
            }
          }
        })
      }
    },

    async messageReaction(hash: string, reaction: string) {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/react_to_message`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            dm_hash: hash,
            reaction: reaction
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Reaction> } }) => {
            if (context.response.ok) {
              // Update the message with the new reaction
              const message = this.messages.find((m: Message) => m.hash === hash)
              if (message) {
                message.reaction = reaction
              }
            } else {
              console.error(`Error reacting to message:`, context.response?._data)
              throw new Error("Failed to react to message.")
            }
          }
        })
      }
    },

    async messageSend(payload: MessagePayload) {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/send_message`
      
      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: 'POST',
          body: {
            direct_message: {
              guest_username: payload.guestUsername,
              text: payload.text,
              tip_amount: payload.tipAmount || 0,
              token_count: payload.unlockAmount || 0,
              media: payload.media
            }
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Message> } }) => {
            if (context.response.ok) {
              // Add the new message to the messages array
              this.addMessage(context.response._data)
            } else {
              if (context.response?._data?.sender_id) {
                console.error(`Error sending message:`, context.response?._data)
                throw new Error(context.response._data.sender_id)
              } else {
                console.error(`Error sending message:`, context.response?._data)
                throw new Error('There was an issue sending the message. Please try again.')
              }
            }
          }
        })
      }
    },

    async messageDelete(hash: string) {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/delete_message`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: 'POST',
          body: {
            dm_hash: hash
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Message> } }) => {
            if (context.response.ok) {
              // Remove the message from the messages array
              this.messages = this.messages.filter((m: Message) => m.hash != hash) 
            } else {
              console.error("Error deleting message:", context.response?._data)
              throw new Error("Failed to delete message.")
            }
          }
        })
      }
    },

    async messagePurchase(message: Message) {
      const config = useRuntimeConfig()
      
      await $api(`${config.public.API_URL}/api/conversations/${message.id}/unlock`, {
        method: 'POST',

        onResponse: (context: { response: { _data: ApiResponse<Message> } }) => {
          if (context.response.ok) {
            const index = this.messages.findIndex((m: Message) => m.id === message.id)
            if (index) {
              this.messages[index].locked = false
              this.messages[index].purchased = true
              this.messages[index].media.forEach((media: MessageMedia) => {
                media.locked = false
              })
            }
          } else {
            console.error("Error purchasing message:", context.response?._data)
            if (context.response._data?.funds) {
              throw new Error(context.response._data.funds)
            } else {
              throw new Error('There was an issue unlocking the message. Please try again.')
            }
          }
        }
      })
    },

    async messageReportAbuse(hash: string) {
      const userStore = useUserStore()
      const config = useRuntimeConfig()
      const endpoint = `${config.public.API_URL}/api/conversations/flag_message`

      if (userStore.loggedIn) {
        await $api(endpoint, {
          method: "POST",
          body: {
            dm_hash: hash,
          },
  
          onResponse: (context: { response: { _data: ApiResponse<Message> } }) => {
            if (context.response.ok) {
              const index = this.messages.findIndex((m: Message) => m.hash === hash)
              this.messages[index].flagged = true

              setTimeout(() => {
                this.messages.splice(index, 1) // Remove the message from the array after 3 seconds
              }, 3000)
            } else {
              console.error('Error reporting message abuse:', context.response?._data)
              throw new Error('There was an issue reporting the message.')
            }
          }
        })
      }
    },

    /******************************************************
     *   	Helper Functions
     *****************************************************/
    getAllConversationIds() {
      return [...this.new.map((c: Conversation) => c.id), ...this.contacts.map((c: Conversation) => c.id)]
    },

    sortConversations(data: Conversation[]) {
      return data.sort((a, b) => {
        const dateA = a.lastSentAt ? new Date(a.lastSentAt).getTime() : (this.sort === 'asc' ? -Infinity : Infinity);
        const dateB = b.lastSentAt ? new Date(b.lastSentAt).getTime() : (this.sort === 'asc' ? -Infinity : Infinity);
    
        return this.sort === 'asc' ? dateA - dateB : dateB - dateA;
      });
    },

    moveConversationFromNewToContacts(conversation: Conversation) {
      // Find the index of the conversation in the new array
      const index = this.new.findIndex((c: Conversation) => c.id === conversation.id)

      if (index !== -1) {
        // Remove the conversation from the new array
        const [removedConversation] = this.new.splice(index, 1)
        
        // Add it to the contacts array
        this.contacts.unshift(removedConversation)
        return true
      } else {
        return false
      }
    },

    moveConversationFromContactsToNew(conversation: Conversation) {
      // Find the index of the conversation in the contacts array
      const index = this.contacts.findIndex((c: Conversation) => c.id === conversation.id)

      if (index !== -1) {
        // Remove the conversation from the contacts array
        const [removedConversation] = this.contacts.splice(index, 1)
        
        // Add it to the new array
        this.new.unshift(removedConversation)
        return true
      } else {
        return false
      }
    },
    
    /******************************************************
     *   	State Actions
     *****************************************************/ 
    async setOrder(order: string) {
      this.order = order
    },

    async setSort(sort: string) {
      this.sort = sort
      this.sortConversations(this.new)
      this.sortConversations(this.contacts)
    },
    
    async setActive(conversation: Conversation) {
      this.loading.messages = true
      this.active = conversation
      this.messages = []
      await this.fetchMessages()

      // Mark conversation as read
      await this.markCurrentConversationsAsRead()
      await this.moveConversationFromNewToContacts(conversation)
      await this.updateUnreadMessageCount()
      this.loading.messages = false
    },

    async setActiveByUsername(username: string) {
      let conversation = null
      conversation = this.contacts.find((c: Conversation) => c.guest.username === username)
      if (!conversation) {
        conversation = this.new.find((c: Conversation) => c.guest.username === username)
      }
      if (conversation) {
        await this.setActive(conversation)
      }
    },

    async clearActive() {
      this.active = null
      this.messages = []
    },

    addMessage(message: Message) {
      return !this.messages.map((m: Message) => m.id).includes(message.id) 
        ? this.messages.push(message)
        : null
    },

    async addNewMessage(message: Message) {
      // Find conversation index in contacts
      const contactIndex = this.contacts.findIndex((c: Conversation) => c.guest.username === message.sender.username)
      
      if (contactIndex !== -1) {
        // Clone conversation
        const clone = JSON.parse(JSON.stringify(this.contacts[contactIndex]))
      
        if (clone) {
          // Update conversation with new message and timestamp
          clone.lastMessageText = message.text
          clone.lastSentAt = message.sentAt
          
          // Remove conversation from contacts
          this.contacts.splice(contactIndex, 1)

          // Move conversation to new if not already present
          if (!this.new.map((c: Conversation) => c.id).includes(clone.id)) {
            this.new.unshift(clone)
          }
        }
      } 
      
      if (!contactIndex) {
        // Find conversation in new
        const newIndex = this.new.findIndex((c: Conversation) => c.guest.username === message.sender.username)

        if (newIndex !== -1) {
          // Update conversation in new with message text and timestamp
          this.new[newIndex].lastMessageText = message.text
          this.new[newIndex].lastSentAt = message.sentAt
        }
      }

      if (!contactIndex && !newIndex) {
        // TODO: Implement fetch convo from server by username
      }
      await this.updateUnreadMessageCount()
    },

    purchasedMessage(hash: string) {
      const index = this.messages.findIndex((m: Message) => m.hash === hash)
      if (index) {
        this.messages[index].locked = false
        this.messages[index].purchased = true
        this.messages[index].media.forEach((media: MessageMedia) => {
          media.locked = false
        })
      }
    },

    unlockMessage(message: Message) {
      const index = this.messages.findIndex((m: Message) => m.hash === message.hash)
      if (index) {
        this.messages[index] = message
      }
    },

    removeMessage(hash: string) {
      this.messages = this.messages.filter((m: Message) => m.hash != hash)
    },

    addSelected(conversationId: string) {
      if (!this.selected.includes(conversationId)) {
        this.selected.push(conversationId)
      }
    },

    addReaction(payload: Reaction) {
      const index = this.messages.findIndex((m: Message) => m.hash === payload.directMessageHash) 
      if (index) {
        this.messages[index].reaction = payload.reaction
      }
    },

    removeSelected(conversationId: string) {
      const index = this.selected.indexOf(conversationId)
      if (index !== -1) this.selected.splice(index, 1)
    },

    clearAllSelected() {
      this.selected = []
    },

    reset() {
      this.active = null
      this.loading.search = false
      this.loading.conversations = false
      this.loading.messages = false
      this.new = []
      this.contacts = []
      this.selected = []
      this.messages = []
      this.search.query = ''
      this.search.results = []
      this.page = 1
      this.perPage = PER_PAGE
      this.order = "default"
      this.sort = "desc"
      this.totalPages = 0
      this.totalItems = 0
    } 
  },
})