export default defineAppConfig({
  ui: {
    primary: "pink",
    cool: "cool",
    modal: {
      width: "sm:min-w-[300px]",
      background: "bg-shark-100 dark:bg-shark-950",
      overlay: {
        background: "bg-black/65 dark:bg-shark-950/30 backdrop-blur-[2px] backdrop-brightness-50 transition-opacity",
      }
    },
    notification: {
      title: "text-base",
      background: "bg-shark-100 dark:bg-shark-900"
    },
    notifications: {
      position: "top-0 bottom-auto",
    },
    popover: {
      overlay: {
        base: "fixed inset-0 transition-opacity z-50",
        background: "bg-gray-100/90 dark:bg-black/90",
      },
      background: "bg-shark-100 dark:bg-shark-950",
      rounded: "rounded-xl",
    },
    tooltip: {
      base: "[@media(pointer:coarse)]:hidden h-6 p-4 text-xs font-normal truncate relative flex items-center animate-fade",
      background: "bg-indigo-500 dark:bg-indigo-300",
      color: "font-semibold italic text-shark-100",
      container: "z-50",
      rounded: "rounded-lg",
      ring: "ring-0 ring-pink-500/20 dark:ring-white"
    },
    radio: {
      inner: "w-full flex flex-col",
      label: "w-full text-sm font-normal text-gray-700 dark:text-gray-200",
      wrapper:
        "relative w-full flex flex-row items-center px-3 py-1.5 rounded-md hover:bg-cool-100 hover:dark:bg-[#2B3236]",
    },
  },
})
